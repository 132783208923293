import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { NgOtpInputModule } from 'ng-otp-input';
import { validateAllFormFields } from '../../core/utils/validateform';
import { CustomValidators } from '../../core/validators/custom.validator';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utils.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { PwdInputComponent } from "../pwd-input/pwd-input.component";

@Component({
  selector: 'app-admin-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ForgotPasswordComponent, NgOtpInputModule, PwdInputComponent],
  templateUrl: './admin-login.component.html',
  styleUrl: './admin-login.component.scss'
})
export class AdminLoginComponent {
  adminLoginForm!: FormGroup;

  otpInputConfig = {
    length: 5,
    containerClass: 'text-center',
    allowNumbersOnly: true
  }
  otpInput = new FormControl('')

  constructor(
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService
  ) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isSuperAdmin) {
        this.utilityService.onNavigate('manage-company')
      }
      else if (this.authService.isUser) {
        if (this.authService.isSuperTenant) {
          this.utilityService.onNavigate('manage-sub-company')
        } else if (this.authService.isTenant || this.authService.isSubTenant) {
          this.utilityService.onNavigate('manage-campaigns')
        }
      }
    }
  }

  ngOnInit() {
    this.initLoginForm();
  }

  initLoginForm() {
    this.adminLoginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, CustomValidators.email()]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    })
  }
  onSubmitLogin() {
    if (!this.adminLoginForm.valid) {
      this.utilityService.scrollToFirstInvalidControl()
      validateAllFormFields(this.adminLoginForm)
      return;
    } else {
      const payload = {
        username: this.adminLoginForm.value.emailId,
        password: sha256(this.adminLoginForm.value.password),
        grant_type: 'password'
      }
      this.authService.login(payload).subscribe((res: any) => {
        if (res) {
          this.dataService.saveLoginResponse(res, this.adminLoginForm.value.emailId);
          if (this.authService.isSuperAdmin) {
            this.utilityService.onNavigate('manage-company')
          }
          else if (this.authService.isUser) {
            if (this.authService.isSuperTenant) {
              this.utilityService.onNavigate('manage-sub-company')
            } else if (this.authService.isTenant || this.authService.isSubTenant) {
              this.utilityService.onNavigate('manage-campaigns')
            }
          }
        }
      }
      )
    }
  }

  onClickForgotPwd() {
    this.utilityService.onNavigate('/forgot-password')
  }

}
