import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './core/loader/loader.component';
import { MessageComponent } from './core/message/message.component';
import { AuthService } from './services/auth.service';
import { FooterComponent } from "./components/footer/footer.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, MessageComponent, LoaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showHeader: boolean = false;
  currentUrl: string = ''
  constructor(public authService: AuthService, private route: Router) { }

  ngOnInit() {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this.showHeader = this.authService.isLoggedIn && this.currentUrl != '/login'  && this.currentUrl != '/register' ? true : false
      }
    })

  }

}
