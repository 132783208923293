<div class="login-container claw-marks">
    <div class="row w-100 justify-content-center">
        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
            <div class="pb-4 d-flex justify-content-center align-items-center logo-container">
                <img src="assets/images/logo.png" alt="LeadStack Logo" class="" loading="lazy" width="300px" />
            </div>
            <div class="login-bg">
                <form [formGroup]="adminLoginForm" class="px-2">
                    <h2 class="text-center font-26 fw-bold pt-2 pb-4">Login</h2>
                    <div [ngClass]="showOtpInput?'d-none':'d-block'">
                        <p>To log in ,we will send a one-time password (<b>OTP</b>) to the below email address</p>
                        <div class="mb-4">
                            <label for="emailId">Enter Your Registered Email Id<sup><b class="error-text">
                                        *</b></sup></label>
                            <input type="text" name="emailId" formControlName="emailId" class="form-control">
                            <div class="error-text"
                                *ngIf="utilityService.isFieldInvalidWithTouched('emailId' ,adminLoginForm)">
                                @if(adminLoginForm.get('emailId')?.errors?.['required']){
                                <span>
                                    Please Enter Your Email ID
                                </span>
                                }@else {
                                <span>
                                    Please enter a valid email ID
                                </span>
                                }
                            </div>
                        </div>
                        <div class="text-center pt-1">
                            <button type="button" class="login-btn text-white w-100"
                                [disabled]="adminLoginForm.controls['emailId'].invalid" (click)="onGetOtp()">Get
                                OTP</button>
                        </div>
                    </div>
                    <div [ngClass]="showOtpInput?'d-block':'d-none'">
                        <p>Please enter the one-time password (<b>OTP</b>) sent to
                            {{adminLoginForm.getRawValue().emailId}}
                            <span type="button" class="pointer edit" (click)="onEditEmailId()">
                                Entered the wrong email id ?
                            </span>
                        </p>
                        <ng-otp-input [formCtrl]="otpInput" [config]="otpInputConfig" #ngOtpInput></ng-otp-input>
                        @if(ngOtpInput.otpForm.dirty || ngOtpInput.otpForm.touched || otpInput.touched){
                        <div class="error-text text-center mt-2">
                            @if(otpInput.errors?.['required']){
                            <span>OTP is required !</span>
                            }@else if(otpInput.errors?.['minlength']) {
                            <span>OTP must have 5 digits!</span>
                            }
                        </div>
                        }
                        <div class="text-center py-2 d-flex justify-content-between align-items-center">
                            <button type="button" class="btn pointer edit ps-0" [disabled]="!allowResendOtp"
                                (click)="onResendOtp()">Resend
                                OTP</button>
                            <countdown #cdTimer [config]="countDownConfig" (event)="handleCDTimerEvent($event)" />
                        </div>
                        <div class="text-center pt-4">
                            <button type="button" (click)="onSubmitLogin()"
                                class="login-btn text-white w-100">Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>