import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownComponent, CountdownConfig, CountdownEvent, CountdownModule } from 'ngx-countdown';
import { validateAllFormFields } from '../../core/utils/validateform';
import { CustomValidators } from '../../core/validators/custom.validator';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utils.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { PwdInputComponent } from "../pwd-input/pwd-input.component";

@Component({
  selector: 'app-login-password-less',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ForgotPasswordComponent, NgOtpInputModule, PwdInputComponent, CountdownModule],
  templateUrl: './login-password-less.component.html',
  styleUrl: './login-password-less.component.scss'
})
export class LoginPasswordLessComponent implements OnInit{
  adminLoginForm!: FormGroup;
  emailId: string = ''
  @ViewChild('cdTimer', { static: false }) cdTimer!: CountdownComponent;

  otpInputConfig = {
    length: 5,
    containerClass: 'text-center',
    allowNumbersOnly: true
  }
  otpInput =  new FormControl('', [Validators.required, Validators.minLength(5)]);

  showOtpInput = false
  allowResendOtp = false
  countDownConfig: CountdownConfig = {
    demand: true,
    format: 'mm:ss',
    leftTime: 60
  }

  constructor(
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService
  ) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isSuperAdmin) {
        this.utilityService.onNavigate('manage-company')
      }
      else if (this.authService.isUser) {
        if (this.authService.isSuperTenant) {
          this.utilityService.onNavigate('manage-sub-company')
        } else if (this.authService.isTenant || this.authService.isSubTenant) {
          this.utilityService.onNavigate('manage-campaigns')
        }
      }
    }
  }

  ngOnInit() {
    this.initLoginForm();
  }

  initLoginForm() {
    this.adminLoginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, CustomValidators.email()]],
      otp: this.otpInput
    })
  }
  onGetOtp() {
    //call the service
    //if response successful start timer

    this.showOtpInput = true
    if (this.emailId == this.adminLoginForm.controls['emailId'].value) {
      this.cdTimer.resume();
    } else {
      if (this.emailId == '') {
        this.cdTimer.begin()
      } else {
        this.cdTimer.restart();
        this.cdTimer.begin()
      }
    }
    this.emailId = this.adminLoginForm.controls['emailId'].value
  }

  onSubmitLogin() {
    if (!this.adminLoginForm.valid) {
      this.utilityService.scrollToFirstInvalidControl()
      validateAllFormFields(this.adminLoginForm)
      return;
    } else {
      const data = {
        username: this.adminLoginForm.value.emailId,
        password: sha256(this.adminLoginForm.value.otp),
        grant_type: 'password'
      }
      this.authService.login(data).subscribe((res: any) => {
        if (res) {
          this.dataService.saveLoginResponse(res, this.adminLoginForm.value.emailId);
          if (this.authService.isSuperAdmin) {
            this.utilityService.onNavigate('manage-company')
          }
          else if (this.authService.isUser) {
            if (this.authService.isSuperTenant) {
              this.utilityService.onNavigate('manage-sub-company')
            } else if (this.authService.isTenant || this.authService.isSubTenant) {
              this.utilityService.onNavigate('manage-campaigns')
            }
          }
        }
      }
      )
    }
  }

  onResendOtp() {
    //callservice and restart timer ??
    this.cdTimer.restart()
    this.cdTimer.begin()
  }

  onEditEmailId() {
    this.cdTimer.pause()
    this.showOtpInput = false
  }

  handleCDTimerEvent(event: CountdownEvent) {
    if (event.action == 'done') {
      this.allowResendOtp = true
    }

  }
}
