import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { DataService } from '../../services/data.service';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { UtilityService } from '../../services/utils.service';
import { ICompany } from '../../interfaces/company.interface';

@Component({
  selector: 'app-select-subtenant',
  standalone: true,
  imports: [RouterModule, MatFormField, MatSelect, MatLabel, MatOption, ReactiveFormsModule, MatOptgroup],
  templateUrl: './select-tenant.component.html',
  styleUrl: './select-tenant.component.scss',
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic'
    }
  }]
})
export class SelectTenantComponent implements OnInit {

  selectTenantControl = new FormControl(this.selectedTenantService.getSelectedTenant() || '', Validators.required)
  subTenantList: ICompany[] = []

  constructor(public dataServices: DataService, public utilityService: UtilityService, private selectedTenantService: selectedTenantService, public router: Router
  ) { }

  ngOnInit(): void {
    this.getAllSubtenants()
    this.onSubtenantValueChanges()
    this.isTenantWabaVerified()
  }

  getAllSubtenants(): void {
    this.selectedTenantService.getSubCompanies(this.dataServices.getLocalStorageItem('tenant_id') || '').subscribe((res) => {
      this.subTenantList = res.response || []
    })
  }
  onSubtenantValueChanges() {
    this.selectTenantControl.valueChanges.subscribe(res => {
      if (res) {
        this.selectedTenantService.setSelectedTenant(res)
      }
    })
  }

  isTenantWabaVerified() {
    this.selectedTenantService.selectedTenantValue.subscribe(val => {
      if (this.router.url == '/waba-verification') {
        this.utilityService.onNavigate('manage-campaign')
      }
    })
  }

}
