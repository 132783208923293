<div class="flex">
  <div class="text-center px-4 w-50">
    <img src="assets/images/logo.png" class="w-100" alt="LeadsTacts" loading="lazy">
    @if(authService.isSuperTenant){
    <h2 class="font-18 pt-5 pb-3">The main account's Whatsapp business account (WABA) is not verified. <u>Please contact admin</u>.
    </h2>
    <p class="font-16">
      You can still continue by selecting any sub tenant account .If no sub tenant are available then start by creating
      one.
    </p>
    }@else {
    <h2 class="font-18 py-5">Whatsapp business account (WABA) is not verified. <u>Please contact admin</u>.</h2>
    <button class="logout-btn text-white my-5" (click)="onLogout()" type="button">Logout</button>
    }
  </div>
</div>