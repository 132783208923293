import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '../../../common-components/modal/modal.component';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-waba-verify',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './waba-verify.component.html',
  styleUrl: './waba-verify.component.scss'
})
export class WabaVerifyComponent {

  constructor(public authService: AuthService) {
  }

  onLogout() {
    this.authService.forceLogout();
  }

}
