import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { SuperAdminAuthGuard } from './guards/super-admin.guard';
import { AllUserGuard } from './guards/all-user.guard';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { WabaGuard } from './guards/waba.guard';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CompanyAdminAuthGuard } from './guards/company-admin.guard';
import { superTenantGuard } from './guards/super-tenant.guard';
import { notSubtenantGuard } from './guards/not-sub-tenant.guard';
import { WabaVerifyComponent } from './modules/company-admin/waba-verify/waba-verify.component';
import { LoginPasswordLessComponent } from './components/login-password-less/login-password-less.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'home',
        redirectTo: 'login'
    },
    {
        path: 'login',
        component: AdminLoginComponent
    }, {
        path: 'login/password-less',
        component: LoginPasswordLessComponent
    },
    {
        path: 'forgot-password',
        loadComponent: () => import('./components/forgot-password/forgot-password.component').then(comp => comp.ForgotPasswordComponent)
    },
    {
        path: 'set-password',
        component: ResetPasswordComponent
    },
    {
        path: 'change-password',
        loadComponent: () => import('./components/change-password/change-password.component').then(comp => comp.ChangePasswordComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'manage-company',
        loadComponent: () => import('./modules/admin/companies/manage-company/manage-company.component').then(comp => comp.ManageCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "manage-company/add-company",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "manage-company/update-company/:companyId",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "my-profile",
        component: MyProfileComponent,
        canActivate: [AuthGuard, CompanyAdminAuthGuard],

    }, {
        path: 'link-meta-waba',
        loadComponent: () => import('./components/waba-meta/waba-meta.component').then(comp => comp.WabaMetaComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard]
    }, {
        path: 'manage-sub-company',
        loadComponent: () => import('./modules/company-admin/sub-companies/manage-sub-company/manage-sub-company.component').then(comp => comp.ManageSubCompanyComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, superTenantGuard],
    }, {
        path: "manage-sub-company/add-sub-company",
        loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, superTenantGuard],
    }, {
        path: "manage-sub-company/update-sub-company/:subCompanyId",
        loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, superTenantGuard],
    },

    {
        path: "manage-leads",
        loadComponent: () => import('./modules/company-admin/userlist/manage-userlist/manage-userlist.component').then(comp => comp.ManageUserlistComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard, WabaGuard],
    }, {
        path: "manage-leads/upload-leads-list",
        loadComponent: () => import('./modules/company-admin/userlist/add-userlist/add-userlist.component').then(comp => comp.AddUserlistComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, notSubtenantGuard, WabaGuard],

    }, {
        path: "manage-leads/view-leads/:userlistId",
        loadComponent: () => import('./modules/company-admin/userlist/view-users/view-users.component').then(comp => comp.ViewUsersComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard, WabaGuard],

    }, {
        path: "manage-campaigns",
        loadComponent: () => import('./modules/company-admin/campaigns/manage-compaigns/manage-compaigns.component').then(comp => comp.ManageCompaignsComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard, WabaGuard],

    }, {
        path: "manage-campaigns/add-campaign",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, notSubtenantGuard, WabaGuard],

    }, {
        path: "manage-campaigns/update-campaign/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, notSubtenantGuard, WabaGuard],

    }, {
        path: "manage-campaigns/message-status/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/message-status/message-status.component').then(comp => comp.MessageStatusComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard, WabaGuard],

    }, {
        path: "manage-templates",
        loadComponent: () => import('./modules/company-admin/templates/manage-template/manage-template.component').then(comp => comp.ManageTemplateComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, AllUserGuard, WabaGuard],

    }, {
        path: "manage-templates/create-templates",
        loadComponent: () => import('./modules/company-admin/templates/create-template/create-template.component').then(comp => comp.CreateTemplateComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, notSubtenantGuard, WabaGuard],
    },
    {
        path: "waba-verification",
        component: WabaVerifyComponent,
        canActivate: [AuthGuard, CompanyAdminAuthGuard, notSubtenantGuard],
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },

];
