<div class="login-container claw-marks">
  <div class="row w-100 justify-content-center align-items-center">
    <div class="col-12 col-md-7 text-center order-2 order-lg-1">
      <div class="bg-white side-content claw-marks p-5">
        <img src="/assets/images/logo.png" class="d-none d-lg-block m-auto mb-3" alt="LeadStack Logo" loading="eager"
          width="300px" />
        <h1 class="lh-1">
          Welcome to LeadsTacts
          <br class="d-none d-md-block">
          <span class="primary-green"> Simplifying Marketing, Amplifying Growth </span>
        </h1>

        <p class="lh-base">
          At LeadsTacts, we’re here to make digital marketing effortless, impactful, and accessible for everyone.
          Whether you’re running a small business, managing multiple clients at an agency, or scaling an enterprise,
          we bring all your marketing tools into one easy-to-use platform. From WhatsApp Marketing to Social Media
          Management, Lead Segmentation, and API Integrations, LeadsTacts helps you connect with your customers, drive
          engagement, and grow your business—without the hassle of juggling multiple tools.
        </p>

        <h2 class="font-24">Powerful features</h2>
        <div class="row gy-3">
          <div class="col-12 col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="font-18 primary-green">
                  Smarter Customer Connections
                </h4>
                <p class="font-14">We use AI to help you create personalized messages, automate workflows, and build
                  meaningful relationships with your audience, all while saving time and reducing costs.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="font-18 primary-green">
                  One Platform, Endless Possibilities
                </h4>
                <p class="font-14">Imagine managing all your marketing campaigns, customer communication, and insights
                  from one place. LeadsTacts simplifies your work, so you can focus on what really matters—your
                  customers.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="font-18 primary-green">
                  Affordable for All, Scalable for Growth
                </h4>
                <p class="font-14">We believe great tools shouldn’t break the bank. LeadsTacts is designed to work for
                  businesses of all sizes—affordable for SMBs, powerful enough for enterprises, and flexible for
                  agencies.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="font-18 primary-green">
                  Built for Agencies, Loved by Clients
                </h4>
                <p class="font-14">With our Agency Module, you can manage multiple clients effortlessly, create
                  tailored campaigns, share insights, and even offer our tools under your own branding. It’s your
                  secret weapon to scale with confidence.</p>
              </div>
            </div>
          </div>
        </div>

        <h2 class="font-24 mt-2">Our <span class="primary-green"> Pricing & Plans</span></h2>
        <h3>&#8377; 799 <span class="font-14">/month</span> </h3>
        <ol class="text-start justify-self-center">
          <li>Fixed fee for the Platform</li>
          <li>Pay as you go for the whatsapp messages you are sending </li>
          <li>Self registration </li>
          <li>SelF WABA verification
          </li>
          <li>Unlimited leads upload and segmentation</li>
        </ol>

      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4 order-1 order-lg-2">
      <div class="pb-4 d-flex justify-content-center align-items-center logo-container pt-4 pt-lg-0">
        <img src="assets/images/logo.png" alt="LeadStack Logo" class="main-logo d-block d-lg-none mb-3" loading="lazy"
          width="300px" />
      </div>
      <div class="login-bg">
        <form [formGroup]="adminLoginForm" class="px-2">
          <h2 class="text-center font-26 fw-bold pt-2 pb-4">Login</h2>
          <div [ngClass]="showOtpInput == 'none' ?'d-block':'d-none'">
            <p>To log in, we will send a one-time password (<b>OTP</b>) to the below email address</p>
            <div class="mb-4">
              <label for="email">Enter Your Registered Email Id<sup><b class="error-text">
                    *</b></sup></label>
              <input type="text" name="email" formControlName="email" class="form-control">
              <div class="error-text" *ngIf="utilityService.isFieldInvalidWithTouched('email' ,adminLoginForm)">
                @if(adminLoginForm.get('email')?.errors?.['required']){
                <span>
                  Please Enter Your Email ID
                </span>
                }@else {
                <span>
                  Please enter a valid email ID
                </span>
                }
              </div>
            </div>
            <div class="text-center pt-1">
              <button type="button" class="login-btn text-white w-100"
                [disabled]="adminLoginForm.controls['email'].invalid" (click)="onGetOtp()">Get
                OTP</button>
            </div>
          </div>
          <div [ngClass]="showOtpInput === 'otp' && !isAdmin ? 'd-block':'d-none'">
            <p>Please enter the one-time password (<b>OTP</b>) sent to
              {{adminLoginForm.getRawValue().email}}
              <span type="button" class="link" (click)="onEditEmailId()">
                Entered the wrong email id ?
              </span>
            </p>
            <label for="otp">One Time Password (OTP)<sup><b class="error-text">
                  *</b></sup></label>
            <ng-otp-input [formCtrl]="otpInput" [config]="otpInputConfig" #ngOtpInput></ng-otp-input>
            @if(ngOtpInput.otpForm.dirty || ngOtpInput.otpForm.touched || otpInput.touched){
            <div class="error-text text-center mt-2">
              @if(otpInput.errors?.['required']){
              <span>OTP is required !</span>
              }@else if(otpInput.errors?.['minlength']) {
              <span>OTP must have 6 digits!</span>
              }
            </div>
            }
            <div class="text-center py-2 d-flex justify-content-between align-items-center">
              <button type="button" class="btn edit ps-0" [disabled]="!allowResendOtp" (click)="getOtp()">Resend
                OTP</button>
              <countdown #cdTimer [config]="countDownConfig" (event)="handleCDTimerEvent($event)" />
            </div>
            <div class="text-center pt-4">
              <button type="button" (click)="onLogin()" class="register-customer-btn text-white w-100">Login</button>
            </div>
          </div>
          <div [ngClass]="showOtpInput === 'pwd' && isAdmin ?'d-block':'d-none'">
            <p>Welcome back , {{adminLoginForm.getRawValue().email}}
              <span type="button" class="link" (click)="onEditEmailId()">
                Entered the wrong email id ?
              </span>
            </p>
            <div class="mb-2">
              <label [for]="'password'">Enter your Password</label>
              <app-pwd-input label="Enter your Password" formControlName="password" autocomplete="current-password" />
              <div class="error-text" *ngIf="utilityService.checkInvalidAndDirty(adminLoginForm.get('password'))">
                <span *ngIf="adminLoginForm.get('password')?.errors?.['required']">
                  Please enter you password
                </span>
                <span *ngIf="adminLoginForm.get('password')?.errors?.['minlength']">
                  Password must contain minimum 8 characters!
                </span>
              </div>
            </div>

            <div class="text-center pt-4">
              <button type="button" (click)="onLogin()" class="register-customer-btn text-white w-100">Login</button>
            </div>
          </div>
          @if(!isAdmin){
          <div class="mt-4 mb-2 text-center">
            <span>Don't have an account yet ? <a class="register-login-link" (click)="onRegister()">Join
                LeadsTats </a></span>
          </div>
          }


        </form>
      </div>
    </div>
  </div>
</div>