<app-message></app-message>
<app-loader></app-loader>
<div class="main-wrapper">
  <div class="menu-wrapper" *ngIf="showHeader">
    <app-header></app-header>
  </div>
  <div class="content-wrapper pb-5">
    <router-outlet></router-outlet>
  </div>
</div>
