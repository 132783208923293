<div class="position-relative">
    <input type="password" [name]="formControlName" [id]="formControlName" [placeholder]="placeholder || ''"
        [formControlName]="formControlName" class="form-control pe-5" #password [autocomplete]="autocomplete">
    <button type="button" class="eyeIconBtn position-absolute top-50 translate-middle-y"
        (click)="password.type = password.type == 'password'?'text':'password'" alt="">
        @if(password.type == 'password'){
        <img src="/assets/images/view.png" alt="view" loading="eager">
        }@else{
        <img src="/assets/images/hide.png" alt="hide" loading="eager">
        }
    </button>
</div>