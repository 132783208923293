import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { selectedTenantService } from "../services/selectedTenant.service";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: 'root'
})

export class WabaGuard implements CanActivate {
    constructor(private userService: UserService, private authService: AuthService, private selectedTenantService: selectedTenantService, private router: Router) { }

    canActivate(): Observable<boolean> | boolean {
        if (this.authService.isSubTenant || (this.authService.isSuperTenant && this.selectedTenantService.getSelectedTenant() !== 'MAIN')) {
            return true
        } else {
            return this.userService.isWabaVerified()
        }

    }
}