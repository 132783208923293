import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AllUserGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(): boolean {
    if (this.auth.isSubTenant || this.auth.isTenant || this.auth.isSuperTenant) {
      return true;
    }
    this.router.navigate(['404']);
    return false;
  }
}
