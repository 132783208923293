import { Component, inject, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NgControl, ReactiveFormsModule } from '@angular/forms';

const NOOP_VALUE_ACCESSOR: ControlValueAccessor =
{
  writeValue(): void { },
  registerOnChange(): void { },
  registerOnTouched(): void { }
};

@Component({
  selector: 'app-pwd-input',
  standalone: true,
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
  imports: [ReactiveFormsModule],
  templateUrl: './pwd-input.component.html',
  styleUrl: './pwd-input.component.scss'
})

export class PwdInputComponent {
  @Input() formControlName!: string
  @Input() placeholder!: string
  @Input() autocomplete!: 'new-password' | 'current-password'

  ngControl = inject(NgControl, { self: true, optional: true });
  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }

}
