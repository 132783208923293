import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { ICompany } from '../../interfaces/company.interface';
import { DataService } from '../../services/data.service';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utils.service';

@Component({
  selector: 'app-select-subtenant',
  standalone: true,
  imports: [RouterModule, MatFormField, MatSelect, MatLabel, MatOption, ReactiveFormsModule, MatOptgroup],
  templateUrl: './select-tenant.component.html',
  styleUrl: './select-tenant.component.scss',
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic'
    }
  }]
})
export class SelectTenantComponent implements OnInit {

  tenantSelectControl = new FormControl(this.selectedTenantService.getTenantSub() || '', Validators.required)
  subTenantList: ICompany[] = []
  superTenantId!: string

  constructor(public dataServices: DataService, public utilityService: UtilityService,
    private selectedTenantService: selectedTenantService,
    public router: Router,
    private userService: UserService
  ) {
    this.superTenantId = this.dataServices.getLocalStorageItem('tenant_id')
  }

  ngOnInit(): void {
    this.getAllSubtenants()
    this.isTenantWabaVerified()
    this.onSubtenantValueChanges()
  }

  getAllSubtenants(): void {
    this.selectedTenantService.getSubCompanies(this.dataServices.getLocalStorageItem('tenant_id')).subscribe((res) => {
      this.subTenantList = res.response || []
    })
  }
  onSubtenantValueChanges() {
    this.tenantSelectControl.valueChanges.subscribe(res => {
      if (res) {
        this.selectedTenantService.setTenantSub(res)
      }
    })
  }

  isTenantWabaVerified() {
    this.selectedTenantService.tenantSub.subscribe(val => {
      if (val) {
        this.userService.isWabaVerified(val).subscribe(res => {
          if (res && res.response) {
            this.userService.setUserWabaDetail = res.response
            if (res.response.waba_verified) {
              if (res.response.messaging_service_exists) {
                this.utilityService.onNavigate('/manage-campaigns')
              } else {
                this.utilityService.onNavigate('/manage-leads')
              }
            } else {
              this.utilityService.onNavigate('/unverified-waba')
            }
            // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            //   this.router.navigate([this.router.url]);
            // });
          }
        })
      }
    })
  }

}
