import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: 'root'
})

export class WabaGuard implements CanActivate {

    constructor(private userService: UserService, private authService: AuthService, private router: Router) { }
    canActivate(): Observable<boolean> | boolean {
        if (this.userService.getUserWabaDetail?.waba_verified) {
            //this.authService.isSubTenant 
            return true
        } else {
            this.router.navigate(['unverified-waba'])
            return false
        }

    }
}