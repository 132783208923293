import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from "../../core/loader/loader.component";

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [RouterModule, LoaderComponent],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {

}
