import { Routes } from '@angular/router';
import { LoginPasswordLessComponent } from './components/login-password-less/login-password-less.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AllUserGuard } from './guards/all-user.guard';
import { AuthGuard } from './guards/auth.guard';
import { notSubtenantGuard } from './guards/not-sub-tenant.guard';
import { SuperAdminAuthGuard } from './guards/super-admin.guard';
import { superTenantGuard } from './guards/super-tenant.guard';
import { WabaGuard } from './guards/waba.guard';
import { allowMessagingGuard } from './guards/allow-messaging.guard';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'home',
        redirectTo: 'login'
    },
    {
        path: 'login',
        component: LoginPasswordLessComponent
    },
    {
        path: 'register',
        loadComponent: () => import('./components/register/register.component').then(comp => comp.RegisterComponent)
    },
    {
        path: "verify-sender-number",
        loadComponent: () => import('./components/sender-number-verification/sender-number-verification.component').then(comp => comp.SenderNumberVerificationComponent),
        canActivate: [AuthGuard, AllUserGuard]
    },
    {
        path: "waba-verification-with-facebook",
        loadComponent: () => import('./components/verify-waba-fb/verify-waba-fb.component').then(comp => comp.VerifyWabaFbComponent),
        canActivate: [AuthGuard, AllUserGuard],
    },
    {
        path: "unverified-waba",
        loadComponent: () => import('./components/waba-unverified/waba-unverified.component').then(comp => comp.WabaUnverifiedComponent),
        canActivate: [AuthGuard, AllUserGuard]
    },
    {
        path: 'manage-company',
        loadComponent: () => import('./modules/admin/companies/manage-company/manage-company.component').then(comp => comp.ManageCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "manage-company/add-company",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "manage-company/update-company/:companyId",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],

    }, {
        path: "my-profile",
        component: MyProfileComponent,
        canActivate: [AuthGuard, AllUserGuard],

    }, {
        path: 'manage-sub-company',
        loadComponent: () => import('./modules/company-admin/sub-companies/manage-sub-company/manage-sub-company.component').then(comp => comp.ManageSubCompanyComponent),
        canActivate: [AuthGuard, superTenantGuard],
    }, {
        path: "manage-sub-company/add-sub-company",
        loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
        canActivate: [AuthGuard, superTenantGuard],
    }, {
        path: "manage-sub-company/update-sub-company/:subCompanyId",
        loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
        canActivate: [AuthGuard, superTenantGuard],
    },

    {
        path: "manage-leads",
        loadComponent: () => import('./modules/company-admin/userlist/manage-userlist/manage-userlist.component').then(comp => comp.ManageUserlistComponent),
        canActivate: [AuthGuard, AllUserGuard, WabaGuard],
    }, {
        path: "manage-leads/upload-leads-list",
        loadComponent: () => import('./modules/company-admin/userlist/add-userlist/add-userlist.component').then(comp => comp.AddUserlistComponent),
        canActivate: [AuthGuard, notSubtenantGuard, WabaGuard],

    }, {
        path: "manage-leads/view-leads/:userlistId",
        loadComponent: () => import('./modules/company-admin/userlist/view-users/view-users.component').then(comp => comp.ViewUsersComponent),
        canActivate: [AuthGuard, AllUserGuard, WabaGuard],

    }, {
        path: "manage-campaigns",
        loadComponent: () => import('./modules/company-admin/campaigns/manage-compaigns/manage-compaigns.component').then(comp => comp.ManageCompaignsComponent),
        canActivate: [AuthGuard, AllUserGuard, WabaGuard, allowMessagingGuard],

    }, {
        path: "manage-campaigns/add-campaign",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, notSubtenantGuard, WabaGuard, allowMessagingGuard],

    }, {
        path: "manage-campaigns/update-campaign/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, notSubtenantGuard, WabaGuard, allowMessagingGuard],

    }, {
        path: "manage-campaigns/message-status/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/message-status/message-status.component').then(comp => comp.MessageStatusComponent),
        canActivate: [AuthGuard, AllUserGuard, WabaGuard, allowMessagingGuard],

    }, {
        path: "manage-templates",
        loadComponent: () => import('./modules/company-admin/templates/manage-template/manage-template.component').then(comp => comp.ManageTemplateComponent),
        canActivate: [AuthGuard, AllUserGuard, WabaGuard, allowMessagingGuard],

    }, {
        path: "manage-templates/create-templates",
        loadComponent: () => import('./modules/company-admin/templates/create-template/create-template.component').then(comp => comp.CreateTemplateComponent),
        canActivate: [AuthGuard, notSubtenantGuard, WabaGuard, allowMessagingGuard],
    },
    {
        path: "make-payment",
        loadComponent: () => import('./components/payment-page/payment-page.component').then(comp => comp.PaymentPageComponent)
    },
    {
        path: "privacy-policy",
        loadComponent: () => import('./components/privacy-policy/privacy-policy.component').then(comp => comp.PrivacyPolicyComponent)
    }, {
        path: "refund-policy",
        loadComponent: () => import('./components/refund-policy/refund-policy.component').then(comp => comp.RefundPolicyComponent)
    }, {
        path: "terms-conditions",
        loadComponent: () => import('./components/terms-conditions/terms-conditions.component').then(comp => comp.TermsConditionsComponent)
    }, {
        path: "sender-no-list",
        loadComponent: () => import('./components/sender-no-list/sender-no-list.component').then(comp => comp.SenderNoListComponent),
        canActivate:[AuthGuard,AllUserGuard]
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },

];
