import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const notSubtenantGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)

  if (authService.isSuperTenant || authService.isTenant) {
    return true;
  }
  router.navigate(['404']);
  return false;
};
