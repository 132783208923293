import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';

export const allowMessagingGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService)
  const router = inject(Router)
  if (userService.getUserWabaDetail?.messaging_service_exists) {
    return true;
  } else {
    router.navigate(['sender-no-list'])
    return false
  }
};
