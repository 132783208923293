import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule, NgIf } from '@angular/common';
import { ILoaderState, LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription = new Subscription();
  private requestCount = 0;
  constructor(
    private loaderService: LoaderService
  ) {
    this.requestCount = 0;

  }

  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: ILoaderState) => {
        const self = this;
        setTimeout(() => {
          if (state.show) {
            self.show = state.show;
            self.requestCount++;
          } else {
            self.requestCount--;
            if (self.requestCount <= 0) {
              self.show = state.show;
            }
          }
        }, 0);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
