@if(router.url == '/waba-verification'){
<div class="arrow">
    <span></span>
    <span></span>
    <span></span>
</div>

}
<mat-form-field>
    <mat-label>Select a tenant / sub tenant </mat-label>
    <mat-select name="subtenant" [formControl]="selectTenantControl">
        <mat-option value="MAIN">{{dataServices.getLocalStorageItem('tenant_name')}} (MAIN)</mat-option>
        @if(subTenantList.length>0){
        <mat-optgroup label="Sub-Tenants :">
            @for (subtenant of subTenantList; track subtenant) {
            <mat-option [value]="subtenant.tenant.tenant_id">{{subtenant.tenant.tenant_name}}</mat-option>
            }

        </mat-optgroup>
        }@else {
        <mat-option value="NONE" disabled>No sub companies available.</mat-option>
        }
    </mat-select>
    @if(utilityService.checkInvalidAndDirty(selectTenantControl)){
    <div class="error-text">
        tenant / sub-tenant is required !
    </div>
    }
</mat-form-field>