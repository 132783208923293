<div class="login-container claw-marks" *ngIf="resetPasswordForm">
  <div class="row w-100 justify-content-center">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
      <div class="pb-4 d-flex justify-content-center align-items-center">
        <img src="/assets/images/logo.png" alt="LeadStack Logo" class="" loading="lazy" width="300px" />
      </div>
      <div class="login-bg">
        <h2 class="font-20">{{setPassword ? 'Set Password' : 'Reset Password'}}</h2>
        <p>To make sure your account is secure, please enter a password with atleast 8 characters.</p>
        <form [formGroup]="resetPasswordForm">
          <div class="mb-3">
            <label [for]="'password'"> New Password :
            </label>
            <app-pwd-input formControlName="password" placeholder="New Password"
              autocomplete="new-password"></app-pwd-input>
            <div class="error-text" *ngIf="utilityService.isFieldInvalidWithTouched('password',resetPasswordForm)">
              <span *ngIf="resetPasswordForm.controls['password'].errors?.['required']">
                Password is required!
              </span>
              <span *ngIf="resetPasswordForm.controls['password'].errors?.['minlength']">
                Password must contain minimum 8 characters!
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label [for]="'confirm_password'">Confirm Password :
            </label>
            <app-pwd-input formControlName="confirm_password" placeholder="Confirm password"
              autocomplete="new-password"></app-pwd-input>
            <div class="error-text"
              *ngIf="utilityService.isFieldInvalidWithTouched('confirm_password',resetPasswordForm)">
              <span *ngIf="resetPasswordForm.controls['confirm_password'].errors?.['required']">
                Confirm password is required!
              </span>
              <span *ngIf="resetPasswordForm.controls['confirm_password'].errors?.['mustMatch']">
                Confirm password does not match password!
              </span>
            </div>
          </div>
          <div class="text-center mt-4">
            <button type="button" class="reset-password-btn text-white" (click)="onSubmit()">Confirm</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>