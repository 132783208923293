<div class="login-container claw-marks">
  <div class="row w-100 justify-content-center">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
      <div class="pb-4 d-flex justify-content-center align-items-center">
        <img src="/assets/images/logo.png" alt="LeadStack Logo" loading="eager" width="300px" />
      </div>
      <div class="login-bg pb-5">
        <form [formGroup]="adminLoginForm" class="px-2">
          <h2 class="text-center font-26 fw-bold pt-2 pb-4">Login</h2>
          <div class="mb-4">
            <label for="emailId">Enter Your Email Id</label>
            <input type="text" name="emailId" formControlName="emailId" class="form-control">
            <div class="error-text" *ngIf="utilityService.checkInvalidAndDirty(adminLoginForm.get('emailId'))">
              <span *ngIf="adminLoginForm.get('emailId')?.errors?.['required']">
                Please Enter Your Email ID
              </span>
              <span *ngIf="adminLoginForm.get('emailId')?.errors?.['pattern']">
                Email Id is invalid

              </span>
            </div>
          </div>
          <div class="mb-2">
            <label [for]="'password'">Enter your Password</label>
            <app-pwd-input label="Enter your Password" formControlName="password" autocomplete="current-password" />
            <div class="error-text"
              *ngIf="utilityService.checkInvalidAndDirty(adminLoginForm.get('password')) && adminLoginForm.get('emailId')?.touched ">
              <span *ngIf="adminLoginForm.get('password')?.errors?.['required']">
                Please enter you password
              </span>
              <span *ngIf="adminLoginForm.get('password')?.errors?.['minlength']">
                Password must contain minimum 8 characters!
              </span>
            </div>
          </div>
          <div class="text-end pt-4">
            <span (click)="onClickForgotPwd()" class="pointer underLineHover">
              Forgot Password?
            </span>
          </div>

          <div class="text-center pt-3">
            <button type="button" (click)="onSubmitLogin()" class="login-btn text-white">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
