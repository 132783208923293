import { Component, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  imports: [],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss'
})
export class MyProfileComponent {
  @Output() closedProfile = new EventEmitter<boolean>();
  profileModal :boolean = false;
  constructor(public dataService : DataService, private location:Location){}
  closeProfile(){
    this.location.back()
  }
}
