<div class="container  col-12 col-md-6 col-lg-5 col-xxl-4 d-flex justify-content-center align-items-center pb-5">
  <div class="my-profile">
    <div class="text-end">
      <img src="/assets/images/cross.png" class="pointer close-icon" (click)="closeProfile()" alt="Close"
        loading="eager">
    </div>

    <div class="mt-5">
      <div class="card position-relative justify-content-center text-center p-3 mx-3">
        <img src="/assets/images/user.png" alt="User Image" loading="lazy" class="user-profile-img">
        <p>{{dataService.getLocalStorageItem('user_name')}}</p>
      </div>

      <div class="card">
        <img src="/assets/images/company.png" alt="Company" loading="eager">
        <div>
          <p>{{dataService.getLocalStorageItem('tenant_name')}}</p>
        </div>
      </div>
      <div class="card">
        <img src="/assets/images/email.png" alt="Email" loading="eager">
        <div>
          <p>{{dataService.getLocalStorageItem('emailId')}}</p>
        </div>
      </div>
    </div>
  </div>
</div>