import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { sha256 } from 'js-sha256';
import { validateAllFormFields } from '../../core/utils/validateform';
import { match } from '../../core/validators/match.validator';
import { ISetPwdPayload } from '../../interfaces/user.interface';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utils.service';
import { AuthService } from '../../services/auth.service';
import { PwdInputComponent } from "../pwd-input/pwd-input.component";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, PwdInputComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  token: string = ''
  setPassword: boolean = true;

  constructor(private formBuilder: FormBuilder, public utilityService: UtilityService,
    public activatedRoute: ActivatedRoute, public authService:AuthService,
    private dataService: DataService, private userService: UserService , 
  ) {
    if (this.activatedRoute.snapshot.url[0].path == 'set-password') {
      this.setPassword = true;
    }
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token') || ''
  }

  ngOnInit(): void {
    this.dataService.clearLocalStorage()
    this.initResetPwdForm()
  }

  initResetPwdForm() {
    this.resetPasswordForm = this.formBuilder.group({
      token: [this.token],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]]
    }, {
      validators: [match('password', 'confirm_password')]
    })
    this.resetPasswordForm.controls['confirm_password'].disable()

    this.resetPasswordForm.controls['password'].statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.resetPasswordForm.controls['confirm_password'].enable()
      }
    })
  }
  onSubmit() {
    if (this.resetPasswordForm.valid) {
      let payload: ISetPwdPayload = this.resetPasswordForm.value
      payload.password = sha256(payload.password)
      payload.confirm_password = sha256(payload.confirm_password)
      this.userService.setPassword(payload).subscribe((res) => {
        if (res) {
          this.authService.forceLogout()
        }
      })
    } else {
      validateAllFormFields(this.resetPasswordForm)
      this.utilityService.scrollToFirstInvalidControl()
      return
    }
  }
}
